import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import classNames from "classnames";

import TestimonialBlock from "../components/FlexibleContentSlices/Testimonials/testimonials.js";
import GalleryBlock from "../components/FlexibleContentSlices/GallerySwiper/gallerySwiper";
import TextBlock from "../components/FlexibleContentSlices/TextBlock/textBlock";
import { Accordion } from "../components/FlexibleContentSlices/Accordion/accordion";
import VideoBlock from "../components/FlexibleContentSlices/VideoPlayer/videoPlayer";
import YoutubeEmbed from "../components/FlexibleContentSlices/YoutubeEmbed/youtubeEmbed";
import ImageBlock from "../components/FlexibleContentSlices/ImageBlock/imageBlock";
import HomepageHero from "../components/HomepageHero/homepageHero";
import TextAndImageBlock from "../components/FlexibleContentSlices/TextAndImageBlock/textAndImageBlock";
import PanelsBlock from "../components/FlexibleContentSlices/PanelsBlock/panelsBlock";
import FeaturedArticleBlock from "../components/FlexibleContentSlices/FeaturedArticleBlock/featuredArticleBlock";
import ImageAndImageBlock from "../components/FlexibleContentSlices/ImageAndImageBlock/imageAndImageBlock";

const IndexPage = ({ data, location }) => {
  const { data: pageData } = data.prismicHome;

  // const filteredWork = pageData.work.filter((item) => item.work_item.document);

  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
      hasLightTheme
      hasMenuIcon
    >
      {/* To be removed once merged in main as homepage hero is now a slice / component  /*}

          {/* <div className="w-full h-hero bg-grey-light relative">
        <Slider data={filteredWork} />
        {pageData.intro && (
          <div className="absolute bottom-0 left-0 xl:left-auto xl:right-0 max-w-3xl xl:max-w-2xl z-50 m-ogs mb-32 xl:mb-ogs pointer-events-none">
            <p className="text-white text-lg md:text-xl lg:text-2xl leading-snug">
              {pageData.intro}
            </p>
          </div>
        )}
      </div> */}

      {pageData.slices &&
        pageData.slices.map((slice, index) => {
          let aspectRatio = 0;
          let imageDescriptionPaddingClassList = [
            "lg:flex lg:justify-between mt-8"
          ];
          let spacingClassList = [""];
          let isLastSlice = index === pageData.slices.length - 1;
          let isSecondLastSlice = index === pageData.slices.length - 2;

          let textPaddingClassList = ["lg:w-1/2 self-center"];
          let parentContainerClassList = [
            "flex flex-col-reverse md:items-center lg:flex-row"
          ];

          let imagesContainerClassList = ["my-6 md:my-0"];

          if (slice.primary?.space_below === "Double") {
            parentContainerClassList.push("pb-16 md:pb-32");
            spacingClassList.push("pb-16 md:pb-32");
          } else if (slice.primary?.space_below === "Single") {
            parentContainerClassList.push("pb-6 md:pb-10");
            spacingClassList.push("pb-6 md:pb-10");
          } else if (isSecondLastSlice || isLastSlice) {
            parentContainerClassList.push("mb-0");
            spacingClassList.push("pb-0");
          } else {
            parentContainerClassList.push("pb-8 md:pb-0");
            spacingClassList.push("pb-8 md:pb-0");
          }

          if (slice.primary?.space_above === "Double") {
            parentContainerClassList.push("mt-16 md:mt-32");
            spacingClassList.push("mt-16 md:mt-32");
          }
          if (slice.primary?.space_above === "Single") {
            parentContainerClassList.push("pt-6 md:pt-10");
            spacingClassList.push("pt-6 md:pt-10");
          } else {
            if (isLastSlice) {
              parentContainerClassList.push("pt-0");
              spacingClassList.push("pt-0");
            } else {
              parentContainerClassList.push("mt-8 md:mt-0");
              spacingClassList.push("mt-8 md:mt-0");
            }
          }

          if (slice.primary?.image_alignment === "Left") {
            parentContainerClassList.push("lg:flex-row-reverse");
            if (slice.primary?.image_width === "Reduced") {
              parentContainerClassList.push("px-ogs lg:px-ogs");
              imagesContainerClassList.push("w-full lg:w-1/2");
              textPaddingClassList.push("lg:pl-ogs");

              aspectRatio = 1.4;
            } else if (slice.primary?.image_width === "Full") {
              parentContainerClassList.push("lg:pr-ogs");
              textPaddingClassList.push("px-ogs lg:px-0");
              imagesContainerClassList.push("px-ogs lg:pl-0 w-full lg:w-2/3");
              imageDescriptionPaddingClassList.push("lg:pl-ogs");
              aspectRatio = 1;
            } else if (slice.primary?.image_width === "Wide") {
              parentContainerClassList.push("lg:pr-ogs");
              textPaddingClassList.push("px-ogs");
              imagesContainerClassList.push("lg:w-4/5");
              imageDescriptionPaddingClassList.push("lg:pl-ogs");
              aspectRatio = 2;
            }
          } else {
            if (slice.primary?.image_width === "Reduced") {
              parentContainerClassList.push("px-ogs lg:px-ogs");
              imagesContainerClassList.push("w-full lg:w-1/2");
              textPaddingClassList.push("lg:pr-ogs");

              aspectRatio = 1.4;
            } else if (slice.primary?.image_width === "Full") {
              parentContainerClassList.push("lg:pl-0 lg:pl-ogs");
              textPaddingClassList.push("px-ogs lg:px-0");
              imagesContainerClassList.push("px-ogs lg:pr-0 w-full lg:w-2/3");
              imageDescriptionPaddingClassList.push("lg:pr-ogs");
              aspectRatio = 1;
            } else if (slice.primary?.image_width === "Wide") {
              parentContainerClassList.push(
                "px-ogs lg:px-ogs lg:pr-0 lg:pl-ogs"
              );
              imagesContainerClassList.push("w-full lg:w-4/5");
              imageDescriptionPaddingClassList.push("lg:pr-ogs");
              textPaddingClassList.push("lg:pr-ogs");

              aspectRatio = 2;
            }
          }

          if (slice.slice_type === "homepage_hero") {
            return (
              <HomepageHero
                slice={slice}
                auto_scroll={pageData.enable_auto_scroll}
              />
            );
          }

          if (slice.slice_type === "video_block") {
            return (
              <div
                className={classNames(
                  parentContainerClassList,
                  "relative w-full"
                )}
              >
                <VideoBlock slice={slice} index={index} />
              </div>
            );
          }

          if (slice.slice_type === "text_block") {
            return (
              <div
                key={index}
                className={classNames(spacingClassList, "px-ogs")}
              >
                <TextBlock slice={slice} index={index} />
              </div>
            );
          }

          if (slice.slice_type === "gallery_block") {
            return (
              <div className={classNames(spacingClassList, "md:h-screen")}>
                <GalleryBlock items={slice.items} />
              </div>
            );
          }

          if (slice.slice_type === "featured_article_block") {
            return (
              <div key={index} className={classNames(spacingClassList)}>
                <FeaturedArticleBlock slice={slice} index={index} />
              </div>
            );
          }

          if (slice.slice_type === "testimonial_block") {
            return (
              <div
                key={index}
                className={classNames(spacingClassList, "px-ogs")}
              >
                <TestimonialBlock slice={slice} index={index} />
              </div>
            );
          }

          if (slice.slice_type === "accordion") {
            return (
              <div
                className={classNames(
                  spacingClassList,
                  "bg-white text-eerie-black"
                )}
              >
                <Accordion slice={slice} />
              </div>
            );
          }
          if (slice.slice_type === "image_block") {
            return (
              <ImageBlock
                slice={slice}
                index={index}
                spacingClassList={spacingClassList}
              />
            );
          }
          if (
            slice.slice_type === "text_and_image_block" ||
            slice.slice_type === "cta_block"
          ) {
            return (
              <TextAndImageBlock
                slice={slice}
                index={index}
                parentContainerClassList={parentContainerClassList}
                textPaddingClassList={textPaddingClassList}
                imagesContainerClassList={imagesContainerClassList}
                aspectRatio={aspectRatio}
                imageDescriptionPaddingClassList={
                  imageDescriptionPaddingClassList
                }
              />
            );
          }
          if (slice.slice_type === "youtube_embed_block") {
            return (
              <div
                id={slice.primary.item_id}
                className={classNames(
                  parentContainerClassList,
                  "relative w-full"
                )}
                key={index}
              >
                <YoutubeEmbed slice={slice} index={index} />
              </div>
            );
          }
          if (slice.slice_type === "panels_block") {
            return (
              <PanelsBlock
                slice={slice}
                index={index}
                spacingClassList={spacingClassList}
              />
            );
          }
          if (slice.slice_type === "image___image_block") {
            return (
              <ImageAndImageBlock
                slice={slice}
                spacingClassList={spacingClassList}
              />
            );
          }

          return null;
        })}
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default IndexPage;

export const query = graphql`
  query MyQuery {
    prismicHome {
      data {
        meta_description
        meta_title
        title
        intro
        enable_auto_scroll
        work {
          work_item_image {
            fluid(
              imgixParams: { q: 100 }
              srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
            ) {
              srcSetWebp
              srcWebp
              sizes
              src
              srcSet
              base64
              aspectRatio
            }
          }
          work_item_image_mobile {
            fluid(
              imgixParams: { q: 100 }
              srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
            ) {
              srcSetWebp
              srcWebp
              sizes
              src
              srcSet
              base64
              aspectRatio
            }
          }
          work_item {
            document {
              ... on PrismicWorkSingle {
                uid
                data {
                  title
                }
              }
            }
          }
        }
        slices {
          ... on PrismicHomeSlicesHomepageHero {
            id
            slice_type
            items {
              work_item {
                document {
                  ... on PrismicWorkSingle {
                    id
                    uid
                    data {
                      title
                    }
                  }
                }
              }
              work_item_image_mobile {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
                ) {
                  aspectRatio
                  base64
                  src
                  sizes
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
              work_item_image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
                ) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
          ... on PrismicHomeSlicesYoutubeEmbedBlock {
            id
            slice_type
            primary {
              space_above
              space_below
              video_alignment
              youtube_video_caption {
                html
                text
              }
              video_width
              youtube_video_embed
            }
          }
          ... on PrismicHomeSlicesTextAndImageBlock {
            id
            slice_type
            primary {
              description {
                text
              }
              heading {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              link {
                url
              }
              link_text {
                text
              }
              image_alignment
              image_title {
                text
              }
              text {
                html
              }
              space_above
              space_below
              image_width
            }
          }
          ... on PrismicHomeSlicesVideoBlock {
            id
            slice_type
            primary {
              video_alignment
              video_caption {
                html
                text
              }
              video_width
              video {
                url
              }
              space_above
              space_below
              vimeo_url {
                url
              }
            }
          }
          ... on PrismicHomeSlicesPanelsBlock {
            id
            slice_type
            items {
              heading {
                text
              }
              subheading {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              link {
                url
              }
              link_text {
                text
              }
              text {
                html
              }
            }
            primary {
              space_above
              space_below
              image_layout
              title1 {
                text
              }
            }
          }
          ... on PrismicHomeSlicesCtaBlock {
            id
            slice_type
            primary {
              heading {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
              link {
                url
              }
              text {
                html
              }
              image_alignment
              link_text {
                text
              }
              background_colour
              image_width
              space_below
              space_above
            }
          }
          ... on PrismicHomeSlicesImageBlock {
            id
            slice_type
            primary {
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              image_style
              space_below
              space_above
            }
          }
          ... on PrismicHomeSlicesAccordion {
            id
            slice_type
            items {
              accordion_text {
                html
              }
              accordion_title {
                text
              }
            }
            primary {
              accordion_heading {
                text
              }
              space_below
              space_above
            }
          }
          ... on PrismicHomeSlicesTextBlock {
            id
            slice_type
            primary {
              description {
                html
              }
              header {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              image_heading {
                text
              }
              image_text {
                text
              }
              link {
                url
              }
              link_text {
                text
              }
              image_alignment
              space_above
              space_below
            }
            items {
              panel_body {
                text
              }
              panel_header {
                text
              }
            }
          }
          ... on PrismicHomeSlicesTestimonialBlock {
            id
            slice_type
            items {
              category {
                text
              }
              client_body {
                html
              }
              client_name {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              project_name {
                text
              }
            }
            primary {
              testimonial_title {
                text
              }
              space_above
              space_below
            }
          }
          ... on PrismicHomeSlicesFeaturedArticleBlock {
            id
            items {
              article_body {
                html
              }
              blog_article_title {
                text
              }
              featured_article {
                text
              }
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              link {
                url
              }
              link_text {
                text
              }
            }
            slice_type
            primary {
              space_above
              space_below
              header {
                text
              }
            }
          }
          ... on PrismicHomeSlicesImageImageBlock {
            id
            slice_type
            primary {
              large_image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              large_image_position
              large_image_text {
                text
              }
              small_image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
              space_above
              space_below
              small_image_text {
                text
              }
            }
          }
          ... on PrismicHomeSlicesGalleryBlock {
            id
            slice_type
            primary {
              space_below
              space_above
            }
            items {
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1280, 1920, 2400]
                ) {
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
                fixed {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
